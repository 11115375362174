import Error from 'next/error'
import { useRouter } from 'next/router'
import { useEffect } from 'react'

const NotFound = ({
  errorCode,
  replace = {
    pathname: '/',
    hash: 'jobs'
  }
}: {
  errorCode: number
  replace?: { pathname: string; hash?: string | undefined }
}) => {
  const router = useRouter()

  useEffect(() => {
    setTimeout(() => {
      router.replace(replace)
    }, 2000)
  }, [replace, router, router.replace])

  return <Error statusCode={errorCode} />
}

export default NotFound
